<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="330px"
        persistent
    >
        <template #icon>
            <v-icon color="indigo accent-2">fas fa-user-plus</v-icon>
        </template>

        <CreateUserForm
            ref="form"
            v-model="newUser"
            @submit="confirm"
            :disabled="loading"
            :isFormValid.sync="isFormValid"
        />

        <small>{{ $t('modals.CreateUserModal.email_note') }}</small>

    </BaseModal>
</template>

<script>
import CreateUserForm from '@/components/forms/CreateUserForm'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from './BaseModalMixin'
import AdminUser from '@/models/AdminUser'
import { mapGetters } from 'vuex'

export default {
    name: 'CreateUserModal',
    components: {
        BaseModal, CreateUserForm
    },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        title: vm.$t('modals.CreateUserModal.title'),

        newUser: new AdminUser,
        loading: false,
        isFormValid: false,
    }),
    methods: {
        async beforeOpen() {
            this.newUser = new AdminUser
            return true
        },

        triggerSubmit() {
            this.$refs.form.submit()
        },

        async beforeConfirm(v) {
            if (!this.isFormValid)
                return false

            // [TEMP][FID-371] Por enquanto todos começam como admins
            this.newUser.roles = [
                { role: 'operator', company_id: this.selected_company_id }
            ]

            this.loading = true
            let res = await this.newUser.create()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.resolveValue = new AdminUser(res.data)
            return true
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },

        ...mapGetters({
            company_id: 'company/id',
            selected_company_id: 'company/selected_company_id',
        }),
    },
}
</script>