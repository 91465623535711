<template>
    <cp-text-field
        ref="input"
        type="text"
        :value="computedValue"
        @input="onInput"
        v-on="listeners"
        v-bind="attrs"
    >
    </cp-text-field>
</template>

<script>
export default {
    name: 'EmailInput',
    inheritAttrs: false,
    props: {
        value: String,
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            
            this.$emit('input', value ? value : null)
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>