<template>
    <!-- Versão desktop -->
    <tr v-if="$vuetify.breakpoint.mdAndUp">
        <td class="py-4">
            <div class="font-weight-bold">{{ item.url }}</div>
        </td>
        <td class="py-4">
            <ul class="pl-0">
                <li v-for="type in item.types" :key="type" class="type-list-item">
                    {{ getLabelForType(type) }}
                </li>
            </ul>
        </td>
        <td class="py-4">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="detailsWebhookRegister" icon>
                        <v-icon v-on="on" small color="secondary_font">
                            fas fa-fw fa-plus
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.details_webhook_register') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="updateWebhookRegister" icon>
                        <v-icon small color="secondary_font">$cp_edit</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.update_webhook_register') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="deleteWebhookRegister" icon>
                        <v-icon small color="remove">$cp_trash</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.remove_webhook_register') }}</span>
            </v-tooltip>
        </td>
    </tr>

    <!-- Versão mobile -->
    <tr v-else>
        <td class="py-4">
            <div class="d-flex flex-row justify-center mb-2 font-weight-bold">
                {{ item.url }}
            </div>

            <v-divider></v-divider>

            <div class="d-flex flex-column align-center my-2">
                <div v-for="type in item.types" :key="type" class="type-list-item">
                    {{ getLabelForType(type) }}
                </div>
            </div>

            <v-divider></v-divider>

            <div class="d-flex flex-row my-2 justify-center">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="detailsWebhookRegister" icon>
                            <v-icon v-on="on" small color="secondary_font">
                                fas fa-fw fa-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.details_webhook_register') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="updateWebhookRegister" icon>
                            <v-icon small color="secondary_font">$cp_edit</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.update_webhook_register') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="deleteWebhookRegister" icon>
                            <v-icon small color="remove">$cp_trash</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.remove_webhook_register') }}</span>
                </v-tooltip>
            </div>
        </td>
    </tr>
</template>

<script>
import WebhookRegister      from '@/models/WebhookRegister'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { mapGetters }       from 'vuex'

export default {
    name: 'WebhooksTableRow',
    mixins: [ HasErrorHandlerMixin ],
    props: {
        item: WebhookRegister,
        index: Number,
        confirm: {
            required: true
        },
    },
    data: vm => ({
        loading: false,
    }),
    methods: {
        updateWebhookRegister() {
            this.$emit('updateWebhookRegister', this.item)
        },
        detailsWebhookRegister() {
            this.$emit('detailsWebhookRegister', this.item)
        },
        deleteWebhookRegister() {
            this.$emit('deleteWebhookRegister', this.item)
        },
        getLabelForType(type) {
            return WebhookRegister.getLabelForType(type)
        },
    },
    computed: {
        ...mapGetters({
            admin: 'auth/user'
        }),
    },
}
</script>

<style scoped lang="scss">
.type-list-item {
    list-style: none;
}
</style>