import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=bd256540&"
import script from "./Tabs.vue?vue&type=script&lang=js&"
export * from "./Tabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VRow,VSpacer,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bd256540')) {
      api.createRecord('bd256540', component.options)
    } else {
      api.reload('bd256540', component.options)
    }
    module.hot.accept("./Tabs.vue?vue&type=template&id=bd256540&", function () {
      api.rerender('bd256540', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/settings/Tabs.vue"
export default component.exports