<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">

        <v-subheader class="pl-0 section-subtitle">{{ $t('forms.AnalyticsForm.gtm') }}</v-subheader>
        <v-row>
            <v-col>
                <cp-text-field
                    ref="gtm_id_field"
                    :label="$t('forms.AnalyticsForm.gtm_id.label')"
                    :hint="$t('forms.AnalyticsForm.gtm_id.hint')"
                    :placeholder="$t('forms.AnalyticsForm.gtm_id.placeholder')"
                    type="text"
                    v-model="analytics.gtm_id"
                    :error-messages="getErrorMessages('gtm_id')"
                    @input="clearErrorMessages('gtm_id')"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import CompanySettings from '@/models/CompanySettings'
import FormMixin       from '@/mixins/FormMixin'

export default {
    name: 'AnalyticsForm',
    mixins: [ FormMixin(CompanySettings, 'gtm_id_field') ],
    computed: {
        analytics() {
            return this.value.analytics
        },
    },
}
</script>

<style scoped lang="scss">
.section-subtitle {
    font-weight: bold;
}
</style>