var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mb-2 mb-md-4 mx-5 mx-md-10" },
    [
      _c("v-col", { staticClass: "ma-0 pa-0", attrs: { cols: "12" } }, [
        _c("span", { staticClass: "pl-5 section-title" }, [
          _vm._v(_vm._s(_vm.$t("views.settings.Tabs.title")))
        ])
      ]),
      _c(
        "v-col",
        { staticClass: "px-0 px-md-3 pt-2", attrs: { cols: "12" } },
        [
          _c(
            "v-tabs",
            {
              staticClass: "mb-2",
              attrs: {
                height: "40px",
                color: "primary",
                "slider-color": "primary",
                "background-color": "background",
                "show-arrows": ""
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm._l(_vm.tabs, function(item, index) {
                return _c(
                  "v-tab",
                  {
                    key: index,
                    staticClass: "tab-title",
                    attrs: {
                      disabled: item.disabled,
                      "active-class": "tab-title-active"
                    }
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                )
              }),
              _c("v-spacer"),
              _vm._t("tab-btn")
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            _vm._l(_vm.tabs, function(item, index) {
              return _c(
                "v-tab-item",
                { key: index, staticClass: "px-4" },
                [_c(item.component, { tag: "component" })],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }