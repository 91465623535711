<template>
    <!-- Versão desktop -->
    <tr v-if="$vuetify.breakpoint.mdAndUp">
        <td class="py-4">
            <div class="font-weight-bold">{{ item.name }}</div>
            <div>{{ item.email }}</div>
        </td>
        <td class="pa-1 py-4">
            <SimpleRoleInput class="more-dense-input" :label="$t('views.settings.tabs_items.Users.users_table.role_label')"
                outlined hide-details dense
                :value="item.getMainRole()"
                @input="onRoleInput"
                :loading="loading"
                :disabled="item.disabled || loading"
            />
        </td>
        <td class="pa-1 py-4">
            <SimpleCompanyInput class="more-dense-input" :label="$t('views.settings.tabs_items.Users.users_table.company_label')"
                outlined hide-details dense
                :value="item.getMainRole()"
                @input="onRoleInput"
                :loading="loading"
                :disabled="item.disabled || loading"
            />
        </td>
        <td class="py-4">
            <v-progress-circular v-if="loading" indeterminate/>
            <v-tooltip bottom v-else-if="!item.disabled && $can('delete', item)">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs" @click="deleteUser">
                        <v-icon small color="error">$cp_user_minus</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('views.settings.tabs_items.Users.users_table.remove_user') }}</span>
            </v-tooltip>
        </td>
    </tr>
    <!-- Versão mobile -->
    <tr v-else>
        <td class="py-4">
            <div class="py-2">
                <div class="font-weight-bold">{{ item.name }}</div>
                <div>{{ item.email }}</div>
            </div>

            <SimpleRoleInput class="py-2" :label="$t('views.settings.tabs_items.Users.users_table.role_label')"
                outlined hide-details dense
                :value="item.getMainRole()"
                @input="onRoleInput"
                :loading="loading"
                :disabled="item.disabled || loading"
            />

            <SimpleCompanyInput class="py-2" :label="$t('views.settings.tabs_items.Users.users_table.company_label')"
                outlined hide-details dense
                :value="item.getMainRole()"
                @input="onRoleInput"
                :loading="loading"
                :disabled="item.disabled || loading"
            />

            <div class="d-flex justify-center">
                <v-progress-circular v-if="loading" indeterminate/>
                <v-tooltip bottom v-else-if="!item.disabled && $can('delete', item)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs" @click="deleteUser">
                            <v-icon small color="error">fas fa-user-minus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('views.settings.tabs_items.Users.users_table.remove_user') }}</span>
                </v-tooltip>
            </div>
        </td>
    </tr>
</template>

<script>
import SimpleRoleInput      from '@/components/inputs/SimpleRoleInput'
import SimpleCompanyInput   from '@/components/inputs/SimpleCompanyInput'
import AdminUser            from '@/models/AdminUser'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { mapGetters }       from 'vuex'

export default {
    name: 'UsersTableRow',
    components: { SimpleRoleInput, SimpleCompanyInput },
    mixins: [ HasErrorHandlerMixin ],
    props: {
        item: AdminUser,
        index: Number,
        confirm: {
            required: true
        },
    },
    data: vm => ({
        loading: false,
    }),
    methods: {
        async onRoleInput(v) {
            this.item.setMainRole(v)
            await this.updateUser()
        },
        async updateUser() {
            this.loading = true
            await this.item.update(this.item.email)
                .catch(this.errorHandler)
            this.loading = false
            this.$emit('updated', this.item)
        },

        async deleteUser() {
            let allowed = await this.confirm.showConfirmation(this.$t(`views.settings.tabs_items.Users.users_table.confirm_remove_user`, { name: this.item.name }))
                .catch(this.errorHandler)

            if (!allowed)
                return

            this.loading = true
            let res = await this.item.delete()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.Users.users_table.remove_success'), 'success')

            // Erros são tratados no callee
            this.$emit('fetch')
        },
    },
    computed: {
        ...mapGetters({
            admin: 'auth/user'
        }),
    },
}
</script>

<style scoped lang="scss">
.more-dense-input {
    padding: 0;
}
</style>