var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-7 pb-5 px-4" },
    [
      _c("span", { staticClass: "primary--text section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.settings.tabs_items.Integrations.title")))
      ]),
      _vm._l(_vm.activeIntegrations, function(integration) {
        return [
          _c("v-divider", {
            key: "divider-" + integration.key,
            staticClass: "mt-6 mb-4 mb-md-1"
          }),
          _c(integration.settingsComponent, {
            key: integration.key,
            tag: "component",
            attrs: { integration: integration }
          })
        ]
      }),
      _c("v-divider", { staticClass: "mt-6" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }