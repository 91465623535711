var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hasSettings
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mb-6" },
            [
              _c("v-subheader", { staticClass: "pl-0 section-subtitle" }, [
                _vm._v(_vm._s(_vm.$t("forms.CustomFieldForm.label")))
              ]),
              _c("p", { staticClass: "secondary_font--text" }, [
                _vm._v(_vm._s(_vm.$t("forms.CustomFieldForm.hint")))
              ]),
              _vm._l(_vm.models, function(model, idx) {
                return _c(
                  "v-list",
                  { key: model + "-" + idx },
                  [
                    _c("div", { staticClass: "pb-3 primary--text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("forms.CustomFieldForm.models." + model))
                      )
                    ]),
                    _vm._l(_vm.fields(model), function(value, key, i) {
                      return _c(
                        "v-list-item",
                        { key: key + "-" + i },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "8px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "forms.CustomFieldForm.fields." +
                                                        key
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: value.active,
                                  callback: function($$v) {
                                    _vm.$set(value, "active", $$v)
                                  },
                                  expression: "value.active"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }