var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "330px",
        persistent: ""
      },
      on: { cancel: _vm.cancel, confirm: _vm.triggerSubmit },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [
              _c("v-icon", { attrs: { color: "indigo accent-2" } }, [
                _vm._v("fas fa-user-plus")
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c("CreateUserForm", {
        ref: "form",
        attrs: { disabled: _vm.loading, isFormValid: _vm.isFormValid },
        on: {
          submit: _vm.confirm,
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          }
        },
        model: {
          value: _vm.newUser,
          callback: function($$v) {
            _vm.newUser = $$v
          },
          expression: "newUser"
        }
      }),
      _c("small", [_vm._v(_vm._s(_vm.$t("modals.CreateUserModal.email_note")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }