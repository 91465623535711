var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-7 pb-5 px-4" },
    [
      _vm.notAuthorized
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("forms.not_authorized")) + " ")
          ])
        : _vm._e(),
      _vm.networkError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("globals.network_error")) + " ")
          ])
        : _vm._e(),
      _c("span", { staticClass: "primary--text section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.settings.tabs_items.Profile.title")))
      ]),
      _c("ProfileForm", {
        ref: "form",
        attrs: { isFormValid: _vm.isFormValid, disabled: _vm.loading },
        on: {
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          },
          submit: _vm.submit
        },
        scopedSlots: _vm._u([
          {
            key: "submit",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "float-right",
                        attrs: {
                          type: "submit",
                          width: _vm.$vuetify.breakpoint.smAndDown
                            ? "100%"
                            : "86px",
                          depressed: "",
                          color: "primary",
                          loading: _vm.loading,
                          disabled: !_vm.isFormValid
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.localAdmin,
          callback: function($$v) {
            _vm.localAdmin = $$v
          },
          expression: "localAdmin"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }