<template>
    <v-select
        ref="input"
        :value="computedValue"
        @input="onInput"
        v-on="listeners"
        v-bind="attrs"
        :items="availableRoles"
    >
    </v-select>
</template>

<script>
export default {
    name: 'SimpleRoleInput',
    inheritAttrs: false,
    props: {
        value: Object,
    },
    data: vm => ({
        localValue: vm.value,
        availableRoles: [
            'admin',
            'marketing',
            'operator',
            // 'dev', // não está disponível para seleção via painel
        ],
    }),
    methods: {
        onInput(value) {
            this.$emit('input', Object.assign(this.localValue, { role: value }))
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue.role
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>
