var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-7 pb-5 px-4" },
    [
      _c("ConfirmationModal", { ref: "confirmation_modal" }),
      _c("span", { staticClass: "primary--text section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.settings.tabs_items.Analytics.title")))
      ]),
      _vm.hasError
        ? _c(
            "div",
            { staticClass: "mt-6" },
            [
              _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("views.settings.tabs_items.Analytics.fetch_error")
                  )
                )
              ])
            ],
            1
          )
        : _vm.shouldShowForm
        ? _c("AnalyticsForm", {
            ref: "form",
            staticClass: "pt-3",
            attrs: {
              value: _vm.companySettings,
              isFormValid: _vm.isFormValid,
              disabled: _vm.loading
            },
            on: {
              "update:isFormValid": function($event) {
                _vm.isFormValid = $event
              },
              "update:is-form-valid": function($event) {
                _vm.isFormValid = $event
              },
              submit: _vm.submit
            },
            scopedSlots: _vm._u([
              {
                key: "submit",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              width: _vm.$vuetify.breakpoint.smAndDown
                                ? "100%"
                                : "86px",
                              depressed: "",
                              color: "primary",
                              loading: _vm.loading,
                              disabled: !_vm.isFormValid
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : !_vm.loading
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("views.settings.tabs_items.Analytics.update_error")
                ) +
                " "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }