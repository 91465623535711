<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field
                    ref="name_field"
                    :label="$t('forms.CreateUserForm.name.label')"
                    :hint="$t('forms.CreateUserForm.name.hint')"
                    :placeholder="$t('forms.CreateUserForm.name.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                    :disabled="disabled || loading"
                    autofocus
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="py-0">
                <EmailInput
                    ref="email_field"
                    :label="$t('forms.CreateUserForm.email.label')"
                    :hint="$t('forms.CreateUserForm.email.hint')"
                    :placeholder="$t('forms.CreateUserForm.email.placeholder')"
                    required type="email"
                    :error-messages="getErrorMessages('email')"
                    v-model="value.email"
                    @input="clearErrorMessages('email')"
                    :disabled="disabled || loading"
                    @keyup.enter="submit"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import EmailInput from '@/components/inputs/EmailInput'
import AdminUser  from '@/models/AdminUser'
import FormMixin  from '@/mixins/FormMixin'

export default {
    name: 'CreateUserForm',
    mixins: [ FormMixin(AdminUser, 'name_field') ],
    components: {
        EmailInput
    },
}
</script>