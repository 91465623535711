<template>
    <div class="pt-7 pb-5 px-4">
        <ConfirmationModal ref="confirmation_modal"/>
        <span class="primary--text section-title">{{ $t('views.settings.tabs_items.Analytics.title') }}</span>
        <div v-if="hasError" class="mt-6">
            <v-alert type="error">{{ $t('views.settings.tabs_items.Analytics.fetch_error') }}</v-alert>
        </div>
        <AnalyticsForm
            ref="form"
            v-else-if="shouldShowForm"
            :value="companySettings"
            :isFormValid.sync="isFormValid"
            :disabled="loading"
            @submit="submit"
            class="pt-3"
        >
            <template #submit>
                <div class="d-flex justify-end">
                    <v-btn type="submit" :width="$vuetify.breakpoint.smAndDown ? '100%' : '86px'" depressed class="float-right" color="primary" :loading="loading" :disabled="!isFormValid">
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </template>
        </AnalyticsForm>
        <v-alert type="error" v-else-if="!loading">
            {{ $t('views.settings.tabs_items.Analytics.update_error') }}
        </v-alert>
    </div>
</template>
<script>
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import AnalyticsForm        from '@/components/forms/AnalyticsForm'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { mapGetters }       from 'vuex'

export default {
    name      : 'AnalyticsTabsItemsSettings',
    mixins    : [ HasErrorHandlerMixin ],
    components: { AnalyticsForm, ConfirmationModal },
    data      : vm => ({
        loading    : false,
        isFormValid: false,
        hasError   : false,

        initialLevelsSystem: undefined,
    }),
    async created() {
        await this.fetchAnalytics()
    },
    methods: {
        async fetchAnalytics() {
            this.loading = true
            await this.$store.dispatch('company/fetchSettings')
                .catch(this.preErrorHandler)
            this.loading = false
        },

        async submit() {
            this.loading = true
            let res = await this.companySettings.update()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.Analytics.update_success'), 'success')
        },

        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        }
    },
    computed: {
        ...mapGetters({
            companySettings: 'company/settings',
        }),

        shouldShowForm() {
            return this.companySettings && this.companySettings.analytics
        },
    },
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>