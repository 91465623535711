var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  label: _vm.$t("forms.CreateUserForm.name.label"),
                  hint: _vm.$t("forms.CreateUserForm.name.hint"),
                  placeholder: _vm.$t("forms.CreateUserForm.name.placeholder"),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("name"),
                  disabled: _vm.disabled || _vm.loading,
                  autofocus: ""
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("name")
                  }
                },
                model: {
                  value: _vm.value.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("EmailInput", {
                ref: "email_field",
                attrs: {
                  label: _vm.$t("forms.CreateUserForm.email.label"),
                  hint: _vm.$t("forms.CreateUserForm.email.hint"),
                  placeholder: _vm.$t("forms.CreateUserForm.email.placeholder"),
                  required: "",
                  type: "email",
                  "error-messages": _vm.getErrorMessages("email"),
                  disabled: _vm.disabled || _vm.loading
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("email")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submit($event)
                  }
                },
                model: {
                  value: _vm.value.email,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "email", $$v)
                  },
                  expression: "value.email"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }