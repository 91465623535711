<template>
    <v-data-table
        :items="computedUsers"
        :headers="headers"
        :loading="loading"
        disable-sort
        disable-pagination
        hide-default-footer
    >
        <template #top>
            <v-toolbar flat>
                <v-toolbar-title class="primary--text font-weight-bold section-title">{{ $t('views.settings.tabs_items.Users.users_table.title') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="actions"></slot>
                <v-btn @click="createUser" outlined height="36px" width="86px" class="primary--text" >{{ $t('actions.add') }}</v-btn>
            </v-toolbar>
        </template>

        <template #item="props">
            <UsersTableRow
                v-bind="props"
                :confirm="$refs.confirm"
                @fetch="$emit('fetch')"
            />
        </template>

        <template #body.append v-if="$can('create', 'AdminUser')">
            <ConfirmationModal ref="confirm"/>
        </template>
    </v-data-table>
</template>

<script>
import UsersTableRow from './UsersTableRow'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import { mapGetters } from 'vuex'

export default {
    name: 'UsersTable',
    components: { UsersTableRow, ConfirmationModal },
    props: {
        users: {
            type: Array,
            required: true,
        },
        loading: Boolean,
    },
    methods: {
        createUser() {
            this.$emit('createUser')
        },
    },
    computed: {
        ...mapGetters({
            admin: 'auth/user',
        }),

        headers() {
            return this.$vuetify.breakpoint.mdAndUp ?
                [
                    { text: this.$t('views.settings.tabs_items.Users.users_table.name_and_email') },
                    { text: this.$t('views.settings.tabs_items.Users.users_table.role'), width: '150px'  },
                    { width: '250px' },
                    { width: '1%' },
                ] : []
        },
        computedUsers() {
            return this.users
                .map(adm => {
                    adm.disabled = adm.isDisabledForPermissionChange()
                    return adm
                })
        },
    },
}
</script>

<style scoped lang="scss">
.td-match-btn-height {
    height: 36px;
}

.more-dense-input {
    padding: 0;
}

.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>