<template>
    <v-col cols="12" class="pr-md-5 py-0">
        <v-row>
            <v-col cols="12" class="py-0">
                <div class="primary--text"><b>{{ $t('forms.WebhookRegisterForm.sections.additional_headers.title') }}</b></div>
            </v-col>
        </v-row>

        <div v-for="(header, index) in value.additional_headers" :key="index" class="pt-3">
            <v-row>
                <v-col cols="12" class="py-0">
                    <cp-text-field
                        ref="key_field"
                        :label="$t('forms.WebhookRegisterForm.sections.additional_headers.key.label')"
                        :hint="$t('forms.WebhookRegisterForm.sections.additional_headers.key.hint')"
                        :placeholder="$t('forms.WebhookRegisterForm.sections.additional_headers.key.placeholder')"
                        required type="text"
                        :error-messages="getErrorMessages('additional_headers[0]')"
                        v-model="header.key"
                        @input="clearErrorMessages('additional_headers[0]')"
                        :disabled="isDetails"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-0">
                    <cp-text-field
                        ref="value_field"
                        :label="$t('forms.WebhookRegisterForm.sections.additional_headers.value.label')"
                        :hint="$t('forms.WebhookRegisterForm.sections.additional_headers.value.hint')"
                        :placeholder="$t('forms.WebhookRegisterForm.sections.additional_headers.value.placeholder')"
                        required type="text"
                        :error-messages="getErrorMessages('additional_headers[0]')"
                        v-model="header.value"
                        @input="clearErrorMessages('additional_headers[0]')"
                        :disabled="isDetails"
                    />
                </v-col>
            </v-row>

            <v-btn v-if="!isDetails" @click="removeHeader(index)" text class="background error--text mb-3">{{ $t('forms.WebhookRegisterForm.sections.additional_headers.remove_header') }}</v-btn>

            <v-divider v-if="!isDetails || !isLastHeader(index)"></v-divider>            
        </div>

        <v-row>
            <v-col cols="12" class="">
                <v-btn v-if="!isDetails && !hasMaxHeaders" @click="addHeader" text class="background primary--text">{{ $t('forms.WebhookRegisterForm.sections.additional_headers.add_header') }}</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import WebhookRegister from '@/models/WebhookRegister'

export default {
    name: 'AdditionalHeadersFieldset',
    mixins: [ FormMixin(WebhookRegister) ],
    props: {
        value: WebhookRegister,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    methods: {
        addHeader() {
            if (this.value.additional_headers) {
                this.value.additional_headers.push({})
                return
            }

            this.value.additional_headers = [{}]
        },
        removeHeader(index) {
            this.value.additional_headers.splice(index, 1)

            // No caso em que é update, deve enviar null caso todos os headers sejam removidos.
            if (this.isUpdate) {
                if (this.value.additional_headers.length == 0)
                    this.value.additional_headers = null
            }
        },
        isLastHeader(index) {
            return this.value.additional_headers && index == this.value.additional_headers.length - 1
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
        isUpdate() {
            return this.formType == 'UPDATE'
        },
        hasMaxHeaders() {
            return this.value.additional_headers && this.value.additional_headers.length == this.$constants.getConstant('WEBHOOK_MAX_ADDITIONAL_HEADERS')
        },
    },
}
</script>