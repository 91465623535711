<template>
    <BaseModal ref="modal"
        @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
        persistent
    >
        <template #icon>
            <v-icon color="indigo accent-2">fas fa-user-plus</v-icon>
        </template>

        <WebhookRegisterForm
            ref="form"
            v-model="webhookRegister"
            formType="DETAILS"
            :disabled="loading"
            :isFormValid.sync="isFormValid"
        />
    </BaseModal>
</template>

<script>
import WebhookRegisterForm from '@/components/forms/WebhookRegisterForm'
import WebhookRegister from '@/models/WebhookRegister'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from './BaseModalMixin'

export default {
    name: 'DetailsWebhookRegisterModal',
    components: {
        BaseModal, WebhookRegisterForm,
    },
    props: {
        webhookRegister: {
            type: [ WebhookRegister ],
            required: true,
        },
    },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        title: vm.$t('modals.DetailsWebhookRegisterModal.title'),

        loading: false,
        isFormValid: false,
    }),
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>