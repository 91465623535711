<template>
    <div class="pt-7 pb-5 px-4">
        <v-alert type="error" v-if="notAuthorized">
            {{ $t('forms.not_authorized') }}
        </v-alert>
        <v-alert type="error" v-if="networkError">
            {{ $t('globals.network_error') }}
        </v-alert>

        <span class="primary--text section-title">{{ $t('views.settings.tabs_items.Profile.title') }}</span>
        <ProfileForm
            ref="form"
            v-model="localAdmin"
            :isFormValid.sync="isFormValid"
            :disabled="loading"
            @submit="submit"
        >
            <template #submit>
                <div class="d-flex justify-end">
                    <v-btn type="submit" :width="$vuetify.breakpoint.smAndDown ? '100%' : '86px'" depressed class="float-right" color="primary" :loading="loading" :disabled="!isFormValid">
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </template>
        </ProfileForm>
    </div>
</template>

<script>
import ProfileForm from '@/components/forms/ProfileForm'
import AdminUser from '@/models/AdminUser'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { mapGetters } from 'vuex'

export default {
    name: 'ProfileTabsItemsSettings',
    components: {
        ProfileForm,
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        localAdmin: vm.admin || new AdminUser,
        loading: false,
        isFormValid: false,
        notAuthorized: false,
        networkError: false
    }),
    created() {
        this.localAdmin = this.$lodash.cloneDeep(this.admin)
    },
    methods: {
        async submit(admin) {
            this.networkError = false
            admin.formatToBack()

            this.loading = true
            this.notAuthorized = false
            let res = await admin.update(this.admin.email)
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            let newAdmin = new AdminUser(res.data.admin)

            newAdmin.formatToFront()

            this.$store.commit('auth/SET_USER', newAdmin)
            if (res.data.token)
                this.$store.commit('auth/SET_TOKEN', res.data.token)

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.Profile.update_success'), 'success')
            // Obs. Se não trocar de rota o número de telefone buga [BUG][TODO]
            // [TODO] Não redirecionar para outra rota
            // Será corrigido no merge, uma vez que o problema de formatação do campo phone já foi
            // corrigido
            this.$router.push({ name: 'home' })
        },

        preErrorHandler(e) {
            if (e.message == 'Network Error')
                this.networkError = true

            let status = this.$lodash.get(e, 'response.status')

            if (status == 403) {
                this.notAuthorized = true
            }

            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            admin: 'auth/user'
        }),
    },
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>