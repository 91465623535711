var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-2 pb-5" },
    [
      _c("UsersTable", {
        attrs: { users: _vm.users, loading: _vm.loading },
        on: { fetch: _vm.fetchUsers, createUser: _vm.createUser }
      }),
      _c("CreateUserModal", { ref: "create_user_modal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }