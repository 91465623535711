<template>
    <v-data-table
        :items="webhookRegisters"
        :headers="headers"
        :loading="loading"
        disable-sort
        disable-pagination
        hide-default-footer
    >
        <template #top>
            <v-toolbar flat>
                <v-toolbar-title class="primary--text font-weight-bold section-title">{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.title') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="actions"></slot>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="createWebhookRegister" :disabled="hasMaxWebhookRegisters" outlined height="36px" width="86px" class="primary--text">
                            {{ $t('actions.add') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.create_webhook_register') }}</span>
                </v-tooltip>
            </v-toolbar>
        </template>

        <template #item="props">
            <WebhookRegistersTableRow
                v-bind="props"
                :confirm="$refs.confirm"
                @fetch="$emit('fetch')"
                @updateWebhookRegister="updateWebhookRegister"
                @detailsWebhookRegister="detailsWebhookRegister"
                @deleteWebhookRegister="deleteWebhookRegister"
            />
        </template>

        <template #body.append v-if="$can('create', 'AdminUser')">
            <ConfirmationModal ref="confirm"/>
        </template>
    </v-data-table>
</template>

<script>
import WebhookRegistersTableRow from './WebhookRegistersTableRow'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import { mapGetters } from 'vuex'

export default {
    name: 'WebhookRegistersTable',
    components: { WebhookRegistersTableRow, ConfirmationModal },
    props: {
        webhookRegisters: {
            type: Array,
            required: true,
        },
        loading: Boolean,
    },
    methods: {
        createWebhookRegister() {
            this.$emit('createWebhookRegister')
        },
        updateWebhookRegister(webhookRegister) {
            this.$emit('updateWebhookRegister', webhookRegister)
        },
        detailsWebhookRegister(webhookRegister) {
            this.$emit('detailsWebhookRegister', webhookRegister)
        },
        deleteWebhookRegister(webhookRegister) {
            this.$emit('deleteWebhookRegister', webhookRegister)
        },
    },
    computed: {
        ...mapGetters({
            admin: 'auth/user',
        }),

        headers() {
            return this.$vuetify.breakpoint.mdAndUp ?
                [
                    { text: this.$t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.url') },
                    { text: this.$t('views.settings.tabs_items.WebhookRegisters.webhook_registers_table.types'), width: '250px' },
                    { width: '150px' },
                ] : []
        },
        hasMaxWebhookRegisters() {
            return this.webhookRegisters.length == this.$constants.getConstant('WEBHOOK_REGISTERS_MAX_NUMBER')
        },
    },
}
</script>

<style scoped lang="scss">
.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>