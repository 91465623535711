var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c("v-subheader", { staticClass: "pl-0 section-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("forms.AnalyticsForm.gtm")))
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("cp-text-field", {
                ref: "gtm_id_field",
                attrs: {
                  label: _vm.$t("forms.AnalyticsForm.gtm_id.label"),
                  hint: _vm.$t("forms.AnalyticsForm.gtm_id.hint"),
                  placeholder: _vm.$t("forms.AnalyticsForm.gtm_id.placeholder"),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("gtm_id")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("gtm_id")
                  }
                },
                model: {
                  value: _vm.analytics.gtm_id,
                  callback: function($$v) {
                    _vm.$set(_vm.analytics, "gtm_id", $$v)
                  },
                  expression: "analytics.gtm_id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }