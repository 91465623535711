var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.mdAndUp
    ? _c("tr", [
        _c("td", { staticClass: "py-4" }, [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.item.name))
          ]),
          _c("div", [_vm._v(_vm._s(_vm.item.email))])
        ]),
        _c(
          "td",
          { staticClass: "pa-1 py-4" },
          [
            _c("SimpleRoleInput", {
              staticClass: "more-dense-input",
              attrs: {
                label: _vm.$t(
                  "views.settings.tabs_items.Users.users_table.role_label"
                ),
                outlined: "",
                "hide-details": "",
                dense: "",
                value: _vm.item.getMainRole(),
                loading: _vm.loading,
                disabled: _vm.item.disabled || _vm.loading
              },
              on: { input: _vm.onRoleInput }
            })
          ],
          1
        ),
        _c(
          "td",
          { staticClass: "pa-1 py-4" },
          [
            _c("SimpleCompanyInput", {
              staticClass: "more-dense-input",
              attrs: {
                label: _vm.$t(
                  "views.settings.tabs_items.Users.users_table.company_label"
                ),
                outlined: "",
                "hide-details": "",
                dense: "",
                value: _vm.item.getMainRole(),
                loading: _vm.loading,
                disabled: _vm.item.disabled || _vm.loading
              },
              on: { input: _vm.onRoleInput }
            })
          ],
          1
        ),
        _c(
          "td",
          { staticClass: "py-4" },
          [
            _vm.loading
              ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
              : !_vm.item.disabled && _vm.$can("delete", _vm.item)
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.deleteUser }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "error" } },
                                    [_vm._v("$cp_user_minus")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      805355727
                    )
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.settings.tabs_items.Users.users_table.remove_user"
                          )
                        )
                      )
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _c("tr", [
        _c(
          "td",
          { staticClass: "py-4" },
          [
            _c("div", { staticClass: "py-2" }, [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.item.name))
              ]),
              _c("div", [_vm._v(_vm._s(_vm.item.email))])
            ]),
            _c("SimpleRoleInput", {
              staticClass: "py-2",
              attrs: {
                label: _vm.$t(
                  "views.settings.tabs_items.Users.users_table.role_label"
                ),
                outlined: "",
                "hide-details": "",
                dense: "",
                value: _vm.item.getMainRole(),
                loading: _vm.loading,
                disabled: _vm.item.disabled || _vm.loading
              },
              on: { input: _vm.onRoleInput }
            }),
            _c("SimpleCompanyInput", {
              staticClass: "py-2",
              attrs: {
                label: _vm.$t(
                  "views.settings.tabs_items.Users.users_table.company_label"
                ),
                outlined: "",
                "hide-details": "",
                dense: "",
                value: _vm.item.getMainRole(),
                loading: _vm.loading,
                disabled: _vm.item.disabled || _vm.loading
              },
              on: { input: _vm.onRoleInput }
            }),
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _vm.loading
                  ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                  : !_vm.item.disabled && _vm.$can("delete", _vm.item)
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { icon: "" },
                                        on: { click: _vm.deleteUser }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", color: "error" } },
                                      [_vm._v("fas fa-user-minus")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "views.settings.tabs_items.Users.users_table.remove_user"
                              )
                            )
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }