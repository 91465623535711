<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">

        <v-subheader class="pl-0 section-subtitle">{{ $t('forms.RulesForm.register_rules') }}</v-subheader>
        <v-checkbox
            class="mt-0"
            :label="$t('forms.RulesForm.is_age_restriction_enabled.label')"
            v-model="value.is_age_restriction_enabled"
            hide-details
            :error-messages="getErrorMessages('is_age_restriction_enabled')"
            @input="clearErrorMessages('is_age_restriction_enabled')"
        ></v-checkbox>

        <v-row v-if="value.is_age_restriction_enabled" class="mb-6">            
            <v-col cols="12" xs="12" sm="12" md="6">
                <AmountInput
                    v-model="value.allow_from_age"
                    :label="$t('forms.RulesForm.allow_from_age.label')"
                    :hint="$t('forms.RulesForm.allow_from_age.hint')"
                    :error-messages="getErrorMessages('allow_from_age')"
                    @input="clearErrorMessages('allow_from_age')"
                    :suffix="$t('globals.years')"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
                <AmountInput
                    v-model="value.allow_to_age"
                    :label="$t('forms.RulesForm.allow_to_age.label')"
                    :hint="$t('forms.RulesForm.allow_to_age.hint')"
                    :error-messages="getErrorMessages('allow_to_age')"
                    @input="clearErrorMessages('allow_to_age')"
                    :suffix="$t('globals.years')"
                />
            </v-col>

            <v-col cols="12" class="py-0">
                <v-checkbox
                    class="mt-0"
                    :label="$t('forms.RulesForm.is_age_gate_enabled.label')"
                    v-model="value.is_age_gate_enabled"
                    hide-details
                    :error-messages="getErrorMessages('is_age_gate_enabled')"
                    @input="clearErrorMessages('is_age_gate_enabled')"
                ></v-checkbox>

                <v-row v-if="value.is_age_gate_enabled">
                    <v-col cols="12" class="pb-0">
                        <cp-text-field
                            v-model="value.age_gate_url"
                            :label="$t('forms.RulesForm.age_gate_url.label')"
                            :hint="$t('forms.RulesForm.age_gate_url.hint')"
                            :error-messages="getErrorMessages('age_gate_url')"
                            @input="clearErrorMessages('age_gate_url')"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-subheader class="pl-0 section-subtitle">{{ $t('forms.RulesForm.block_rules') }}</v-subheader>
        <v-checkbox
            class="mt-0"
            :label="$t('forms.RulesForm.is_generation_via_admin_locked.label')"
            v-model="value.is_generation_via_admin_locked"
            hide-details
            :error-messages="getErrorMessages('is_generation_via_admin_locked')"
            @input="clearErrorMessages('is_generation_via_admin_locked')"
        ></v-checkbox>

        <v-checkbox
            class="mt-0"
            :label="$t('forms.RulesForm.is_consumption_via_admin_locked.label')"
            v-model="value.is_consumption_via_admin_locked"
            hide-details
            :error-messages="getErrorMessages('is_consumption_via_admin_locked')"
            @input="clearErrorMessages('is_consumption_via_admin_locked')"
        ></v-checkbox>

        <v-checkbox
            class="mt-0 mb-10 mb-md-6"
            :label="$t('forms.RulesForm.is_voucher_withdrawal_via_admin_locked.label')"
            v-model="value.is_voucher_withdrawal_via_admin_locked"
            hide-details
            :error-messages="getErrorMessages('is_voucher_withdrawal_via_admin_locked')"
            @input="clearErrorMessages('is_voucher_withdrawal_via_admin_locked')"
        ></v-checkbox>

        <v-subheader class="pl-0 section-subtitle">{{ $t('forms.RulesForm.levels_system_rules') }}</v-subheader>
        <v-row>
            <v-col>
                <SelectInput
                    ref="levels_system_field"
                    :label="$t('forms.RulesForm.levels_system.label')"
                    :value="value.levels_system"
                    :items="levelsSystems"
                    @input="levelsSystemChanged"
                />
            </v-col>
        </v-row>

        <CustomFieldFieldset v-model="value" :models="models"/>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import SelectInput         from '@/shared/components/inputs/SelectInput'
import AmountInput         from '@/components/inputs/AmountInput'
import CompanySettings     from '@/models/CompanySettings'
import CustomFieldFieldset from './fieldset/CustomFieldFieldset'
import FormMixin           from '@/mixins/FormMixin'

export default {
    name: 'RulesForm',
    mixins: [ FormMixin(CompanySettings, 'name_field') ],
    components: {
        AmountInput,
        SelectInput,
        CustomFieldFieldset,
    },
    methods: {
        levelsSystemChanged(value) {
            this.value.levels_system = value
        },
    },
    computed: {
        levelsSystems() {
            return CompanySettings.availableLevelsSystems().map(system => ({
                value: system,
                text : this.$t(`forms.RulesForm.levels_system.available.${system.toLowerCase()}`)
            }))
        },
        models() {
            // Add os nomes dos modelos que aceitam campos customizados
            return ["Customer", "Cookie"]
        }
    },
}
</script>

<style scoped lang="scss">
.section-subtitle {
    font-weight: bold;
}
</style>