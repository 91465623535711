var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-2 pb-5" },
    [
      _c("WebhookRegistersTable", {
        attrs: { webhookRegisters: _vm.webhookRegisters, loading: _vm.loading },
        on: {
          fetch: _vm.fetchWebhookRegisters,
          createWebhookRegister: _vm.createWebhookRegister,
          detailsWebhookRegister: _vm.detailsWebhookRegister,
          updateWebhookRegister: _vm.updateWebhookRegister,
          deleteWebhookRegister: _vm.deleteWebhookRegister
        }
      }),
      _c("CreateWebhookRegisterModal", {
        ref: "create_webhook_register_modal"
      }),
      _c("UpdateWebhookRegisterModal", {
        ref: "update_webhook_register_modal",
        attrs: { webhookRegister: _vm.selectedWebhookRegister }
      }),
      _c("DetailsWebhookRegisterModal", {
        ref: "details_webhook_register_modal",
        attrs: { webhookRegister: _vm.selectedWebhookRegister }
      }),
      _c("ConfirmationModal", { ref: "delete_confirmation_modal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }