<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" class="pt-2 pb-0">
                <v-row>
                    <v-col cols="12">
                        <cp-text-field
                            ref="name_field"
                            :label="$t('forms.ProfileForm.name.label')"
                            :hint="$t('forms.ProfileForm.name.hint')"
                            :placeholder="$t('forms.ProfileForm.name.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('name')"
                            v-model="value.name"
                            @input="clearErrorMessages('name')"
                            :disabled="disabled || loading"
                        />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                        <cp-text-field
                            ref="email_field"
                            :label="$t('forms.ProfileForm.email.label')"
                            :hint="$t('forms.ProfileForm.email.hint')"
                            :placeholder="$t('forms.ProfileForm.email.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('email')"
                            v-model="value.email"
                            @input="clearErrorMessages('email')"
                            :disabled="disabled || loading"
                        />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                        <cp-phone-field
                            ref="phone_field"
                            :label="$t('forms.ProfileForm.phone.label')"
                            :hint="$t('forms.ProfileForm.phone.hint')"
                            :placeholder="$t('forms.ProfileForm.phone.placeholder')"
                            required type="phone"
                            :error-messages="getErrorMessages('phone')"
                            v-model="value.phone"
                            @input="clearErrorMessages('phone')"
                            :disabled="disabled || loading"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" class="py-0 pt-md-2">
                <v-row>
                    <v-col cols="12">
                        <cp-password-field
                            autocomplete
                            ref="current_password_field"
                            :label="$t('forms.ProfileForm.current_password.label')"
                            :hint="$t('forms.ProfileForm.current_password.hint')"
                            :placeholder="$t('forms.ProfileForm.current_password.placeholder')"
                            required type="current_password"
                            :error-messages="getErrorMessages('current_password')"
                            v-model="value.current_password"
                            @input="clearErrorMessages('current_password')"
                            :disabled="disabled || loading"
                        />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                        <cp-password-field
                            ref="password_field"
                            :label="$t('forms.ProfileForm.password.label')"
                            :hint="$t('forms.ProfileForm.password.hint')"
                            :placeholder="$t('forms.ProfileForm.password.placeholder')"
                            required type="password"
                            :error-messages="getErrorMessages('password')"
                            v-model="value.password"
                            @input="clearErrorMessages('password')"
                            :disabled="disabled || loading"
                        />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                        <cp-password-field
                            ref="password_confirmation_field"
                            :label="$t('forms.ProfileForm.password_confirmation.label')"
                            :hint="$t('forms.ProfileForm.password_confirmation.hint')"
                            :placeholder="$t('forms.ProfileForm.password_confirmation.placeholder')"
                            required type="password_confirmation"
                            :error-messages="getConfirmationPasswordErrorMessages()"
                            v-model="value.password_confirmation"
                            @input="clearErrorMessages('password_confirmation')"
                            :disabled="disabled || loading"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import AdminUser from '@/models/AdminUser'
import FormMixin from '@/mixins/FormMixin'

export default {
    name: 'ProfileForm',
    mixins: [ FormMixin(AdminUser, 'name_field') ],
    methods: {
        // Confere se a senha do input é igual a sua confimação e, caso não seja, atribuí este erro ao array de erros
        getConfirmationPasswordErrorMessages() {
            let passwordField = this.$refs.password_field
            let passwordConfirmationField = this.$refs.password_confirmation_field

            let mismatchError = []
            if ( passwordField && passwordConfirmationField &&
                 passwordField.localValue != passwordConfirmationField.localValue ) {

                return [this.$t('forms.CustomerForm.password_confirmation.mismatch')].concat(this.getErrorMessages('password_confirmation'))
            }
            
            return this.getErrorMessages('password_confirmation')
        },
    },
}
</script>