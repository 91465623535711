<template>
    <div class="pt-2 pb-5">
        <UsersTable
            :users="users"
            :loading="loading"
            @fetch="fetchUsers"
            @createUser="createUser"
        />
        
        <CreateUserModal ref="create_user_modal"/>
    </div>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import CreateUserModal      from '@/components/modals/CreateUserModal'
import UsersTable           from '@/views/settings/tabs_items/users/UsersTable'
import { mapGetters }       from 'vuex'
import AdminUser            from '@/models/AdminUser'

export default {
    name: 'UsersTabsItemsSettings',
    components: {
        UsersTable, CreateUserModal,
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        loading: false,
        users: [],
    }),
    async created() {
        await this.fetchUsers()
    },
    methods: {
        async fetchUsers() {
            this.loading = true

            // [TODO] pagination
            let res = await AdminUser.list({ params: { company_id: this.selected_company_id, per_page: this.$constants.getConstant('PAGINATION_MAX_PER_PAGE') } })
                .catch(this.errorHandler)

            this.loading = false

            this.users = res
        },

        async createUser() {
            let newUser = await this.$refs.create_user_modal.open()
                .catch(this.errorHandler)

            if (!newUser)
                return

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.Users.users_table.create_success'), 'success')

            // Erros são tratados no callee
            await this.fetchUsers()
        },
    },
    computed: {
        ...mapGetters({
            admin                     : 'auth/user',
            selected_company_id       : 'company/selected_company_id',
            is_parent_company_selected: 'company/is_parent_company_selected',
        }),
    },
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>