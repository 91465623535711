import { required, string, isNull, length } from '@/utils/validation'
import BaseModel from '@/models/BaseModel'
import $constants from '@/services/constants'
import i18n from '@/i18n'
import _ from 'lodash'

export default class WebhookRegister extends BaseModel {

    defaults() {
        return {
            id                : undefined,
            url               : undefined,
            types             : undefined,
            additional_headers: undefined,

            // Configurações de autenticação.
            auth_type   : undefined,
            bearer_token: undefined,

            created_at: undefined,
            updated_at: undefined,
        }
    }

    validation() {
        return {
            url: required.and(string).and(length(1, 191)),
            types: required.and((types) => {
                if (!this.constructor.areTypesValid(types))
                    return i18n.t('models.WebhookRegister.errors.invalid_types')
            }),
            additional_headers: (headers) => {
                if (!headers)
                    return true

                if (headers && headers.length > this.constructor.getMaxAdditionalHeaders())
                    return i18n.t('models.WebhookRegister.errors.too_many_headers')

                for (let header of headers) {
                    if (!header.key || !header.value)
                        return i18n.t('models.WebhookRegister.errors.headers_missing_key_value')
                }
            },

            // Autenticação
            auth_type: (authType) => {
                if (!authType)
                    return true

                if (!this.constructor.isAuthTypeValid(authType))
                    return i18n.t('models.WebhookRegister.errors.invalid_auth_type')
            },
            bearer_token: (bearerToken) => {
                if (!bearerToken) {
                    if (this.auth_type)
                        return i18n.t('models.WebhookRegister.errors.missing_bearer_token')

                    return true
                }

                let testString = string(bearerToken)
                if (testString !== true)
                    return testString                    
            },
        }
    }

    static getAvailableTypes(selectedTypes = []) {
        let availableTypes = [
            'customer_created',
            'customer_updated',
        ]

        return availableTypes.filter(type => !selectedTypes.includes(type))
    }

    static getAvailableAuthTypes() {
        return [
            'bearer_token',
        ]
    }

    static areTypesValid(types) {
        if (!types)
            return false

        let availableTypes = this.getAvailableTypes()
        return types.every(type => availableTypes.includes(type))
    }

    static isAuthTypeValid(authType) {
        if (!authType)
            return true

        let availableAuthTypes = this.getAvailableAuthTypes()
        return availableAuthTypes.includes(authType)
    }

    static getLabelForType(type) {
        if (!type)
            throw new Error('Expect type to be defined.')

        return i18n.t(`models.WebhookRegister.labels.types.${type}`)
    }

    static getLabelForAuthType(authType) {
        if (!authType)
            throw new Error('Expect authType to be defined.')

        return i18n.t(`models.WebhookRegister.labels.auth_types.${authType}`)
    }

    static getMaxAdditionalHeaders() {
        return $constants.getConstant('WEBHOOK_MAX_ADDITIONAL_HEADERS')
    }

    static async list(additionalConfig = {}) {
        // Erros estão sendo tratados no chamador (caller)
        let response = await this.request({
            url: '/webhook',
            method: 'get',
            ...additionalConfig,
        })

        if (!response)
            return

        return this.hydrate(_.get(response, 'data', []))
    }

    async create() {
        let data = this.getData([
            'url', 'types', 'additional_headers', 'auth_type', 'bearer_token',
        ])
        
        let response = await this.request({
            url: '/webhook',
            method: 'post',
            data,
        })

        if (!response || !response.data)
            return

        return new this.constructor(response.data)
    }

    async update() {
        let data = this.getData([
            'url', 'types', 'additional_headers', 'auth_type', 'bearer_token',
        ])
        
        let response = await this.request({
            url: `/webhook/${this.id}`,
            method: 'put',
            data,
        })

        if (!response || !response.data)
            return

        return new this.constructor(response.data)
    }

    async delete() {
        let response = await this.request({
            url: `/webhook/${this.id}`,
            method: 'delete',
        })

        if (!response)
            return

        return response
    }

}