var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pt-2 pb-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileForm.name.label"),
                          hint: _vm.$t("forms.ProfileForm.name.hint"),
                          placeholder: _vm.$t(
                            "forms.ProfileForm.name.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("name"),
                          disabled: _vm.disabled || _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("name")
                          }
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                    },
                    [
                      _c("cp-text-field", {
                        ref: "email_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileForm.email.label"),
                          hint: _vm.$t("forms.ProfileForm.email.hint"),
                          placeholder: _vm.$t(
                            "forms.ProfileForm.email.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("email"),
                          disabled: _vm.disabled || _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("email")
                          }
                        },
                        model: {
                          value: _vm.value.email,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "email", $$v)
                          },
                          expression: "value.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                    },
                    [
                      _c("cp-phone-field", {
                        ref: "phone_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileForm.phone.label"),
                          hint: _vm.$t("forms.ProfileForm.phone.hint"),
                          placeholder: _vm.$t(
                            "forms.ProfileForm.phone.placeholder"
                          ),
                          required: "",
                          type: "phone",
                          "error-messages": _vm.getErrorMessages("phone"),
                          disabled: _vm.disabled || _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("phone")
                          }
                        },
                        model: {
                          value: _vm.value.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "phone", $$v)
                          },
                          expression: "value.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0 pt-md-2",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("cp-password-field", {
                        ref: "current_password_field",
                        attrs: {
                          autocomplete: "",
                          label: _vm.$t(
                            "forms.ProfileForm.current_password.label"
                          ),
                          hint: _vm.$t(
                            "forms.ProfileForm.current_password.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.ProfileForm.current_password.placeholder"
                          ),
                          required: "",
                          type: "current_password",
                          "error-messages": _vm.getErrorMessages(
                            "current_password"
                          ),
                          disabled: _vm.disabled || _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("current_password")
                          }
                        },
                        model: {
                          value: _vm.value.current_password,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "current_password", $$v)
                          },
                          expression: "value.current_password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                    },
                    [
                      _c("cp-password-field", {
                        ref: "password_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileForm.password.label"),
                          hint: _vm.$t("forms.ProfileForm.password.hint"),
                          placeholder: _vm.$t(
                            "forms.ProfileForm.password.placeholder"
                          ),
                          required: "",
                          type: "password",
                          "error-messages": _vm.getErrorMessages("password"),
                          disabled: _vm.disabled || _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("password")
                          }
                        },
                        model: {
                          value: _vm.value.password,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "password", $$v)
                          },
                          expression: "value.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                    },
                    [
                      _c("cp-password-field", {
                        ref: "password_confirmation_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.ProfileForm.password_confirmation.label"
                          ),
                          hint: _vm.$t(
                            "forms.ProfileForm.password_confirmation.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.ProfileForm.password_confirmation.placeholder"
                          ),
                          required: "",
                          type: "password_confirmation",
                          "error-messages": _vm.getConfirmationPasswordErrorMessages(),
                          disabled: _vm.disabled || _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages(
                              "password_confirmation"
                            )
                          }
                        },
                        model: {
                          value: _vm.value.password_confirmation,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "password_confirmation", $$v)
                          },
                          expression: "value.password_confirmation"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }