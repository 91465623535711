<template>
    <v-row class="mb-2 mb-md-4 mx-5 mx-md-10">
        <v-col cols="12" class="ma-0 pa-0">
            <span class="pl-5 section-title">{{ $t('views.settings.Tabs.title') }}</span>
        </v-col>

        <v-col cols="12" class="px-0 px-md-3 pt-2">
            <v-tabs
                v-model="tab"
                height="40px"
                color="primary"
                slider-color="primary"
                background-color="background"
                class="mb-2"
                show-arrows
            >
                <v-tab
                    v-for="(item, index) of tabs"
                    :key="index"
                    :disabled="item.disabled"
                    class="tab-title"
                    active-class="tab-title-active"
                >
                    {{ item.title }}
                </v-tab>
                <v-spacer></v-spacer>
                <slot name="tab-btn"></slot>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(item, index) of tabs"
                    :key="index"
                    class="px-4"
                >
                    <component :is="item.component"></component>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
import WebhookRegistersTabItem from '@/views/settings/tabs_items/WebhookRegisters'
import IntegrationsTabItem from '@/views/settings/tabs_items/Integrations'
import AnalyticsTabItem from '@/views/settings/tabs_items/Analytics'
import ProfileTabItem from '@/views/settings/tabs_items/Profile'
import RulesTabItem from '@/views/settings/tabs_items/Rules'
import UsersTabItem from '@/views/settings/tabs_items/Users'

export default {
    name: 'SettingsTabs',
    data: () => ({
        tab: null,
    }),
    computed: {
        tabs() {
            let tabs = [
                { title: this.$t('views.settings.Tabs.tabs_titles.profile'), component: ProfileTabItem, disabled: false },
            ]

            if (this.$can('create', 'AdminUser'))
                tabs.push({ title: this.$t('views.settings.Tabs.tabs_titles.users'), component: UsersTabItem, disabled: false })

            if (this.$can('edit', 'Company')) {
                tabs.push({ title: this.$t('views.settings.Tabs.tabs_titles.rules'),             component: RulesTabItem,            disabled: false })
                tabs.push({ title: this.$t('views.settings.Tabs.tabs_titles.analytics'),         component: AnalyticsTabItem,        disabled: false })
                tabs.push({ title: this.$t('views.settings.Tabs.tabs_titles.integrations'),      component: IntegrationsTabItem,     disabled: false })
                tabs.push({ title: this.$t('views.settings.Tabs.tabs_titles.webhook_registers'), component: WebhookRegistersTabItem, disabled: false })
            }

            return tabs
        },
    },
}
</script>