<template>
    <div class="pt-7 pb-5 px-4">
        <span class="primary--text section-title">{{ $t('views.settings.tabs_items.Integrations.title') }}</span>
        <template v-for="integration in activeIntegrations">
            <v-divider :key="`divider-${integration.key}`" class="mt-6 mb-4 mb-md-1"></v-divider>
            <component
                :key="integration.key"
                :is="integration.settingsComponent"
                :integration="integration"
            />
        </template>
        <v-divider class="mt-6"></v-divider>
    </div>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'IntegrationsTabsItemsSettings',
    mixins: [ HasErrorHandlerMixin ],
    components: { },
    data: vm => ({
    }),
    async created() {
    },
    methods: {
    },
    computed: {
        activeIntegrations() {
            return this.$integrations.getActiveIntegrations()
        },
    },
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>