var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c("v-subheader", { staticClass: "pl-0 section-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("forms.RulesForm.register_rules")))
      ]),
      _c("v-checkbox", {
        staticClass: "mt-0",
        attrs: {
          label: _vm.$t("forms.RulesForm.is_age_restriction_enabled.label"),
          "hide-details": "",
          "error-messages": _vm.getErrorMessages("is_age_restriction_enabled")
        },
        on: {
          input: function($event) {
            return _vm.clearErrorMessages("is_age_restriction_enabled")
          }
        },
        model: {
          value: _vm.value.is_age_restriction_enabled,
          callback: function($$v) {
            _vm.$set(_vm.value, "is_age_restriction_enabled", $$v)
          },
          expression: "value.is_age_restriction_enabled"
        }
      }),
      _vm.value.is_age_restriction_enabled
        ? _c(
            "v-row",
            { staticClass: "mb-6" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12", sm: "12", md: "6" } },
                [
                  _c("AmountInput", {
                    attrs: {
                      label: _vm.$t("forms.RulesForm.allow_from_age.label"),
                      hint: _vm.$t("forms.RulesForm.allow_from_age.hint"),
                      "error-messages": _vm.getErrorMessages("allow_from_age"),
                      suffix: _vm.$t("globals.years")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("allow_from_age")
                      }
                    },
                    model: {
                      value: _vm.value.allow_from_age,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "allow_from_age", $$v)
                      },
                      expression: "value.allow_from_age"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12", sm: "12", md: "6" } },
                [
                  _c("AmountInput", {
                    attrs: {
                      label: _vm.$t("forms.RulesForm.allow_to_age.label"),
                      hint: _vm.$t("forms.RulesForm.allow_to_age.hint"),
                      "error-messages": _vm.getErrorMessages("allow_to_age"),
                      suffix: _vm.$t("globals.years")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("allow_to_age")
                      }
                    },
                    model: {
                      value: _vm.value.allow_to_age,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "allow_to_age", $$v)
                      },
                      expression: "value.allow_to_age"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0",
                    attrs: {
                      label: _vm.$t(
                        "forms.RulesForm.is_age_gate_enabled.label"
                      ),
                      "hide-details": "",
                      "error-messages": _vm.getErrorMessages(
                        "is_age_gate_enabled"
                      )
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("is_age_gate_enabled")
                      }
                    },
                    model: {
                      value: _vm.value.is_age_gate_enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "is_age_gate_enabled", $$v)
                      },
                      expression: "value.is_age_gate_enabled"
                    }
                  }),
                  _vm.value.is_age_gate_enabled
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c("cp-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "forms.RulesForm.age_gate_url.label"
                                  ),
                                  hint: _vm.$t(
                                    "forms.RulesForm.age_gate_url.hint"
                                  ),
                                  "error-messages": _vm.getErrorMessages(
                                    "age_gate_url"
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.clearErrorMessages(
                                      "age_gate_url"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.value.age_gate_url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.value, "age_gate_url", $$v)
                                  },
                                  expression: "value.age_gate_url"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-subheader", { staticClass: "pl-0 section-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("forms.RulesForm.block_rules")))
      ]),
      _c("v-checkbox", {
        staticClass: "mt-0",
        attrs: {
          label: _vm.$t("forms.RulesForm.is_generation_via_admin_locked.label"),
          "hide-details": "",
          "error-messages": _vm.getErrorMessages(
            "is_generation_via_admin_locked"
          )
        },
        on: {
          input: function($event) {
            return _vm.clearErrorMessages("is_generation_via_admin_locked")
          }
        },
        model: {
          value: _vm.value.is_generation_via_admin_locked,
          callback: function($$v) {
            _vm.$set(_vm.value, "is_generation_via_admin_locked", $$v)
          },
          expression: "value.is_generation_via_admin_locked"
        }
      }),
      _c("v-checkbox", {
        staticClass: "mt-0",
        attrs: {
          label: _vm.$t(
            "forms.RulesForm.is_consumption_via_admin_locked.label"
          ),
          "hide-details": "",
          "error-messages": _vm.getErrorMessages(
            "is_consumption_via_admin_locked"
          )
        },
        on: {
          input: function($event) {
            return _vm.clearErrorMessages("is_consumption_via_admin_locked")
          }
        },
        model: {
          value: _vm.value.is_consumption_via_admin_locked,
          callback: function($$v) {
            _vm.$set(_vm.value, "is_consumption_via_admin_locked", $$v)
          },
          expression: "value.is_consumption_via_admin_locked"
        }
      }),
      _c("v-checkbox", {
        staticClass: "mt-0 mb-10 mb-md-6",
        attrs: {
          label: _vm.$t(
            "forms.RulesForm.is_voucher_withdrawal_via_admin_locked.label"
          ),
          "hide-details": "",
          "error-messages": _vm.getErrorMessages(
            "is_voucher_withdrawal_via_admin_locked"
          )
        },
        on: {
          input: function($event) {
            return _vm.clearErrorMessages(
              "is_voucher_withdrawal_via_admin_locked"
            )
          }
        },
        model: {
          value: _vm.value.is_voucher_withdrawal_via_admin_locked,
          callback: function($$v) {
            _vm.$set(_vm.value, "is_voucher_withdrawal_via_admin_locked", $$v)
          },
          expression: "value.is_voucher_withdrawal_via_admin_locked"
        }
      }),
      _c("v-subheader", { staticClass: "pl-0 section-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("forms.RulesForm.levels_system_rules")))
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("SelectInput", {
                ref: "levels_system_field",
                attrs: {
                  label: _vm.$t("forms.RulesForm.levels_system.label"),
                  value: _vm.value.levels_system,
                  items: _vm.levelsSystems
                },
                on: { input: _vm.levelsSystemChanged }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("CustomFieldFieldset", {
        attrs: { models: _vm.models },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }