<template>
    <v-col cols="12" class="pr-md-5 py-0">
        <v-row>
            <v-col cols="12" class="pt-0">
                <div class="primary--text"><b>{{ $t('forms.WebhookRegisterForm.sections.auth.title') }}</b></div>
            </v-col>
        </v-row>

        <v-row class="pt-1">
            <v-col cols="12" class="py-0">
                <cp-select-field
                    ref="select_auth_type_input"
                    :placeholder="$t('forms.WebhookRegisterForm.sections.auth.auth_type.placeholder')"
                    :label="$t('forms.WebhookRegisterForm.sections.auth.auth_type.label')"
                    :hint="$t('forms.WebhookRegisterForm.sections.auth.auth_type.hint')"
                    :error-messages="getErrorMessages(`filters[0].type`)"
                    :items="authTypes"
                    item-value="code"
                    item-text="label"
                    v-model="value.auth_type"
                    :clearable="true"
                    @input="clearErrorMessages(`filters[0].type`)"
                    :disabled="isDetails"
                    :append-icon="!isDetails ? '$dropdown' : ''"
                >
                </cp-select-field>
            </v-col>
        </v-row>

        <!-- Autenticação via bearer token  -->
        <v-row v-if="isBearerToken">
            <v-col cols="12" class="py-0">
                <cp-text-field
                    ref="key_field"
                    :label="$t('forms.WebhookRegisterForm.sections.auth.bearer_token.label')"
                    :hint="$t('forms.WebhookRegisterForm.sections.auth.bearer_token.hint')"
                    :placeholder="$t('forms.WebhookRegisterForm.sections.auth.bearer_token.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('bearer_token')"
                    v-model="value.bearer_token"
                    @input="clearErrorMessages('bearer_token')"
                    :disabled="isDetails"
                />
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import WebhookRegister from '@/models/WebhookRegister'

export default {
    name: 'AuthFieldset',
    mixins: [ FormMixin(WebhookRegister) ],
    props: {
        value: WebhookRegister,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
        authTypes() {
            let authTypes = WebhookRegister.getAvailableAuthTypes()
            return authTypes.map(authType => {
                return { code: authType, label: WebhookRegister.getLabelForAuthType(authType) }
            })
        },
        isBearerToken() {
            return this.value.auth_type == 'bearer_token'
        },
    },
}
</script>