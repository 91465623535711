<template>
    <div class="pt-7 pb-5 px-4">
        <ConfirmationModal ref="confirmation_modal"/>
        <span class="primary--text section-title">{{ $t('views.settings.tabs_items.Rules.title') }}</span>
        <div v-if="hasError" class="mt-6">
            <v-alert type="error">{{ $t('views.settings.tabs_items.Rules.fetch_error') }}</v-alert>
        </div>
        <RulesForm
            ref="form"
            v-else-if="companySettings"
            :value="companySettings"
            :isFormValid.sync="isFormValid"
            :disabled="loading"
            @submit="submit"
            class="pt-3"
        >
            <template #submit>
                <div class="d-flex justify-end">
                    <v-btn type="submit" :width="$vuetify.breakpoint.smAndDown ? '100%' : '86px'" depressed class="float-right" color="primary" :loading="loading" :disabled="!isFormValid">
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </template>
        </RulesForm>
        <v-alert type="error" v-else-if="!loading">
            {{ $t('views.settings.tabs_items.Rules.update_error') }}
        </v-alert>
    </div>
</template>
<script>
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import RulesForm            from '@/components/forms/RulesForm'
import CompanySettings      from '@/models/CompanySettings'
import { mapGetters }       from 'vuex'

export default {
    name      : 'RulesTabsItemsSettings',
    mixins    : [ HasErrorHandlerMixin ],
    components: { RulesForm, ConfirmationModal },
    data      : vm => ({
        loading    : false,
        isFormValid: false,
        hasError   : false,

        initialLevelsSystem: undefined,
    }),
    async created() {
        await this.fetchRules()
    },
    methods: {
        async fetchRules() {
            this.loading = true
            await this.$store.dispatch('company/fetchSettings')
                .catch(this.preErrorHandler)
            this.loading = false

            // Setta o sistema de níveis inicial para verificar se está sendo alterado
            this.initialLevelsSystem = this.companySettings.levels_system
        },

        async submit() {
            // Verifica se houve alteração no sistema de níveis
            let changingLevelsSystem = false
            if (this.companySettings.levels_system != this.initialLevelsSystem) {
                changingLevelsSystem = true
                let r = await this.$refs.confirmation_modal.showConfirmation(this.$t('views.settings.tabs_items.Rules.confirmation_text'))
                if (!r)
                    return
            }

            this.loading = true
            let res = await this.companySettings.update()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            // Trata caso em que o usuário atualiza o sistema de níveis novamente sem antes sair para outra tela
            if (changingLevelsSystem)
                this.initialLevelsSystem = this.companySettings.levels_system

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.Rules.update_success'), 'success')
        },

        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        }
    },
    computed: {
        ...mapGetters({
            companySettings: 'company/settings',
        }),
    },
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    font-weight: bold;
}
</style>