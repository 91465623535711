<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field
                    ref="url_field"
                    :label="$t('forms.WebhookRegisterForm.url.label')"
                    :hint="$t('forms.WebhookRegisterForm.url.hint')"
                    :placeholder="$t('forms.WebhookRegisterForm.url.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('url')"
                    v-model="value.url"
                    @input="clearErrorMessages('url')"
                    :disabled="isDetails"
                    autofocus
                />
            </v-col>
        </v-row>

        <TypesFieldset v-model="value" class="py-3" :formType="formType" />
        <AuthFieldset v-if="!isDetails || hasAuthType" v-model="value" class="py-3" :formType="formType" />
        <AdditionalHeadersFieldset v-if="!isDetails || hasHeaders" v-model="value" class="py-3" :formType="formType" />

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import AdditionalHeadersFieldset from '@/components/forms/fieldset/webhookRegister/AdditionalHeadersFieldset'
import TypesFieldset             from '@/components/forms/fieldset/webhookRegister/TypesFieldset'
import AuthFieldset              from '@/components/forms/fieldset/webhookRegister/AuthFieldset'
import WebhookRegister           from '@/models/WebhookRegister'
import FormMixin                 from '@/mixins/FormMixin'

export default {
    name: 'WebhookRegisterForm',
    mixins: [ FormMixin(WebhookRegister, 'url_field') ],
    components: { TypesFieldset, AdditionalHeadersFieldset, AuthFieldset },
    props: {
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
        hasAuthType() {
            return this.value.auth_type
        },
        hasHeaders() {
            return this.value.additional_headers && this.value.additional_headers.length
        },
    },
}
</script>