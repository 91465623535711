var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.mdAndUp
    ? _c("tr", [
        _c("td", { staticClass: "py-4" }, [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.item.url))
          ])
        ]),
        _c("td", { staticClass: "py-4" }, [
          _c(
            "ul",
            { staticClass: "pl-0" },
            _vm._l(_vm.item.types, function(type) {
              return _c("li", { key: type, staticClass: "type-list-item" }, [
                _vm._v(" " + _vm._s(_vm.getLabelForType(type)) + " ")
              ])
            }),
            0
          )
        ]),
        _c(
          "td",
          { staticClass: "py-4" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.detailsWebhookRegister }
                              },
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    attrs: {
                                      small: "",
                                      color: "secondary_font"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v(" fas fa-fw fa-plus ")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1586447922
                )
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.details_webhook_register"
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.updateWebhookRegister }
                              },
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { small: "", color: "secondary_font" }
                                },
                                [_vm._v("$cp_edit")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3287186750
                )
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.update_webhook_register"
                      )
                    )
                  )
                ])
              ]
            ),
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.deleteWebhookRegister }
                              },
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "remove" } },
                                [_vm._v("$cp_trash")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3210415622
                )
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.remove_webhook_register"
                      )
                    )
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    : _c("tr", [
        _c(
          "td",
          { staticClass: "py-4" },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row justify-center mb-2 font-weight-bold"
              },
              [_vm._v(" " + _vm._s(_vm.item.url) + " ")]
            ),
            _c("v-divider"),
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center my-2" },
              _vm._l(_vm.item.types, function(type) {
                return _c("div", { key: type, staticClass: "type-list-item" }, [
                  _vm._v(" " + _vm._s(_vm.getLabelForType(type)) + " ")
                ])
              }),
              0
            ),
            _c("v-divider"),
            _c(
              "div",
              { staticClass: "d-flex flex-row my-2 justify-center" },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.detailsWebhookRegister }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      attrs: {
                                        small: "",
                                        color: "secondary_font"
                                      }
                                    },
                                    on
                                  ),
                                  [_vm._v(" fas fa-fw fa-plus ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.details_webhook_register"
                          )
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.updateWebhookRegister }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "secondary_font"
                                    }
                                  },
                                  [_vm._v("$cp_edit")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.update_webhook_register"
                          )
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.deleteWebhookRegister }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "remove" } },
                                  [_vm._v("$cp_trash")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.remove_webhook_register"
                          )
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }