var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pr-md-5 py-0", attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "primary--text" }, [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "forms.WebhookRegisterForm.sections.additional_headers.title"
                    )
                  )
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._l(_vm.value.additional_headers, function(header, index) {
        return _c(
          "div",
          { key: index, staticClass: "pt-3" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12" } },
                  [
                    _c("cp-text-field", {
                      ref: "key_field",
                      refInFor: true,
                      attrs: {
                        label: _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.key.label"
                        ),
                        hint: _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.key.hint"
                        ),
                        placeholder: _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.key.placeholder"
                        ),
                        required: "",
                        type: "text",
                        "error-messages": _vm.getErrorMessages(
                          "additional_headers[0]"
                        ),
                        disabled: _vm.isDetails
                      },
                      on: {
                        input: function($event) {
                          return _vm.clearErrorMessages("additional_headers[0]")
                        }
                      },
                      model: {
                        value: header.key,
                        callback: function($$v) {
                          _vm.$set(header, "key", $$v)
                        },
                        expression: "header.key"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12" } },
                  [
                    _c("cp-text-field", {
                      ref: "value_field",
                      refInFor: true,
                      attrs: {
                        label: _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.value.label"
                        ),
                        hint: _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.value.hint"
                        ),
                        placeholder: _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.value.placeholder"
                        ),
                        required: "",
                        type: "text",
                        "error-messages": _vm.getErrorMessages(
                          "additional_headers[0]"
                        ),
                        disabled: _vm.isDetails
                      },
                      on: {
                        input: function($event) {
                          return _vm.clearErrorMessages("additional_headers[0]")
                        }
                      },
                      model: {
                        value: header.value,
                        callback: function($$v) {
                          _vm.$set(header, "value", $$v)
                        },
                        expression: "header.value"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.isDetails
              ? _c(
                  "v-btn",
                  {
                    staticClass: "background error--text mb-3",
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.removeHeader(index)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "forms.WebhookRegisterForm.sections.additional_headers.remove_header"
                        )
                      )
                    )
                  ]
                )
              : _vm._e(),
            !_vm.isDetails || !_vm.isLastHeader(index)
              ? _c("v-divider")
              : _vm._e()
          ],
          1
        )
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              !_vm.isDetails && !_vm.hasMaxHeaders
                ? _c(
                    "v-btn",
                    {
                      staticClass: "background primary--text",
                      attrs: { text: "" },
                      on: { click: _vm.addHeader }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "forms.WebhookRegisterForm.sections.additional_headers.add_header"
                          )
                        )
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }