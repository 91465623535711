<template>
    <v-select
        ref="input"
        :value="computedValue"
        @input="onInput"
        v-on="listeners"
        v-bind="attrs"
        :items="availableCompanies"
        :loading="computedLoading"
        item-text="name"
        item-value="id"
        :disabled="disabled"
    >
    </v-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'SimpleCompanyInput',
    inheritAttrs: false,
    props: {
        value   : Object,
        loading : Boolean,
        disabled: Boolean,
    },
    data: vm => ({
        localValue        : vm.value,
        availableCompanies: [],
        localLoading      : false,
    }),
    async created() {
        await this.fetchCompanies()
    },
    methods: {
        onInput(value) {
            this.$emit('input', Object.assign(this.localValue, { company_id: value }))
        },

        focus() {
            this.$refs.input.focus()
        },

        async fetchCompanies() {
            this.localLoading = true
            let res = await this.$axios.get(`/company/${this.company_id}/subsidiary`)
                .catch(this.errorHandler)
            this.localLoading = false

            if (!res)
                return

            let adminOnCompanies = this.jwtContent.roles
                .map(r => r.role == 'admin' ? r.company_id : null)
                .filter(r => !!r)

            if (adminOnCompanies.includes(this.company_id))
                adminOnCompanies = true

            this.availableCompanies = [
                { id: this.company_id, name: this.company_name },
                ...res.data,
            ]
                // Filtra a troca para empresas que este autenticado tem acesso
                .filter(c => adminOnCompanies === true || adminOnCompanies.includes(c.id))

            this.checkForSingleCompany()
        },

        /**
         * Verifica se há apenas uma opção de empresa, se sim seleciona-a automaticamente
         */
        checkForSingleCompany() {
            if (this.computedValue || this.availableCompanies.length > 1)
                return

            this.$nextTick(() => {
                this.onInput(this.availableCompanies[0].id)
            })
        },
    },
    computed: {
        computedValue() {
            return this.localValue.company_id
        },

        ...mapGetters({
            company_id  : 'company/id',
            company_name: 'company/name',
            jwtContent  : 'auth/jwtContent',
        }),

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, loading, ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, disabled, ...attrs } = this.$attrs
            return { ...attrs }
        },
        computedLoading(value) {
            return this.localLoading || this.loading
        },
        computedDisabled(value) {
            return !this.$can('create', 'AdminUser') || this.disabled
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>
