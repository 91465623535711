<template>
    <v-row v-if="!hasSettings">
        <v-col class="mb-6">
            <v-subheader class="pl-0 section-subtitle">{{ $t('forms.CustomFieldForm.label') }}</v-subheader>

            <p class="secondary_font--text">{{ $t('forms.CustomFieldForm.hint') }}</p>
            <v-list v-for="(model, idx ) in models" :key="`${model}-${idx}`">
                <div class="pb-3 primary--text">{{ $t(`forms.CustomFieldForm.models.${model}`) }}</div>

                <v-list-item v-for="(value, key, i) in fields(model)" :key="`${key}-${i}`">
                        <v-list-item-action>
                            <v-checkbox
                                class="mt-0"
                                v-model="value.active"
                            >
                                <template v-slot:label>
                                    <span style="margin-left: 8px">
                                        {{ $t(`forms.CustomFieldForm.fields.${key}`)}}
                                    </span>
                                </template>
                            </v-checkbox>
                        </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
import FormMixin       from '@/mixins/FormMixin'
import CompanySettings from '@/models/CompanySettings'

export default {
    name   : 'CustomFieldFieldset',
    mixins : [ FormMixin(CompanySettings) ],
    props: {
        value: CompanySettings,
        models: {
            type: Array,
            required: true,
        },
    },
    created() {
        let company       = new CompanySettings()
        this.formSettings = company.customFields
    },
    computed: {
        hasSettings() {
            return this.$lodash.isEmpty(this.value.customFields)
        },
    },
    methods: {
        fields(model) {
            return this.value.customFields[model]
        }
    }
}
</script>

<style scoped lang="scss">
.section-subtitle {
    font-weight: bold;
}

.v-list-item {
    padding: 0 !important;
    min-height: 28px !important;
}

.v-list-item__action {
    margin: 0 !important;
}
</style>
