var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          ref: "input",
          attrs: {
            value: _vm.computedValue,
            items: _vm.availableCompanies,
            loading: _vm.computedLoading,
            "item-text": "name",
            "item-value": "id",
            disabled: _vm.disabled
          },
          on: { input: _vm.onInput }
        },
        "v-select",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }