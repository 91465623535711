<template>
    <div class="pt-2 pb-5">
        <WebhookRegistersTable
            :webhookRegisters="webhookRegisters"
            :loading="loading"
            @fetch="fetchWebhookRegisters"
            @createWebhookRegister="createWebhookRegister"
            @detailsWebhookRegister="detailsWebhookRegister"
            @updateWebhookRegister="updateWebhookRegister"
            @deleteWebhookRegister="deleteWebhookRegister"
        />
        
        <CreateWebhookRegisterModal ref="create_webhook_register_modal" />
        <UpdateWebhookRegisterModal ref="update_webhook_register_modal" :webhookRegister="selectedWebhookRegister" />
        <DetailsWebhookRegisterModal ref="details_webhook_register_modal" :webhookRegister="selectedWebhookRegister" />
        <ConfirmationModal ref="delete_confirmation_modal"/>
    </div>
</template>

<script>
import WebhookRegistersTable       from '@/views/settings/tabs_items/webhooks/WebhookRegistersTable'
import CreateWebhookRegisterModal  from '@/components/modals/CreateWebhookRegisterModal'
import UpdateWebhookRegisterModal  from '@/components/modals/UpdateWebhookRegisterModal'
import DetailsWebhookRegisterModal from '@/components/modals/DetailsWebhookRegisterModal'
import ConfirmationModal           from '@/components/modals/ConfirmationModal'
import HasErrorHandlerMixin        from '@/mixins/HasErrorHandlerMixin'
import WebhookRegister             from '@/models/WebhookRegister'
import { mapGetters }              from 'vuex'

export default {
    name: 'WebhookRegistersTabsItemsSettings',
    components: {
        WebhookRegistersTable,
        CreateWebhookRegisterModal,
        DetailsWebhookRegisterModal,
        UpdateWebhookRegisterModal,
        ConfirmationModal,
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        loading: false,
        webhookRegisters: [],
        selectedWebhookRegister: new WebhookRegister
    }),
    async created() {
        await this.fetchWebhookRegisters()
    },
    methods: {
        async fetchWebhookRegisters() {
            this.loading = true

            let res = await WebhookRegister.list({ params: { company_id: this.selected_company_id, per_page: this.$constants.getConstant('PAGINATION_MAX_PER_PAGE') } })
                .catch(this.errorHandler)

            this.loading = false

            this.webhookRegisters = res
        },
        async createWebhookRegister() {
            let webhookRegister = await this.$refs.create_webhook_register_modal.open()
                .catch(this.errorHandler)
            
            if (!webhookRegister)
                return

            this.selectedWebhookRegister = webhookRegister

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.WebhookRegisters.create_success'), 'success')

            await this.fetchWebhookRegisters()
        },
        async updateWebhookRegister(webhookRegister) {
            this.selectedWebhookRegister = new WebhookRegister(this.$lodash.cloneDeep(webhookRegister))
            webhookRegister = await this.$refs.update_webhook_register_modal.open()
                .catch(this.errorHandler)

            if (!webhookRegister)
                return

            this.selectedWebhookRegister = webhookRegister

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.WebhookRegisters.update_success'), 'success')

            await this.fetchWebhookRegisters()
        },
        async detailsWebhookRegister(webhookRegister) {
            this.selectedWebhookRegister = webhookRegister
            this.$refs.details_webhook_register_modal.open()
        },
        async deleteWebhookRegister(webhookRegister) {
            let allowed = await this.$refs.delete_confirmation_modal.showConfirmation(this.$t(`views.settings.tabs_items.WebhookRegisters.confirm_remove`, { url: webhookRegister.url }))
                .catch(this.errorHandler)

            if (!allowed)
                return

            this.loading = true
            let res = await webhookRegister.delete()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.settings.tabs_items.WebhookRegisters.remove_success'), 'success')

            await this.fetchWebhookRegisters()
        },
    },
    computed: {
        ...mapGetters({
            selected_company_id: 'company/selected_company_id',
        }),
    },
}
</script>