import { render, staticRenderFns } from "./UsersTable.vue?vue&type=template&id=20f4a82b&scoped=true&"
import script from "./UsersTable.vue?vue&type=script&lang=js&"
export * from "./UsersTable.vue?vue&type=script&lang=js&"
import style0 from "./UsersTable.vue?vue&type=style&index=0&id=20f4a82b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f4a82b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VDataTable,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20f4a82b')) {
      api.createRecord('20f4a82b', component.options)
    } else {
      api.reload('20f4a82b', component.options)
    }
    module.hot.accept("./UsersTable.vue?vue&type=template&id=20f4a82b&scoped=true&", function () {
      api.rerender('20f4a82b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/settings/tabs_items/users/UsersTable.vue"
export default component.exports