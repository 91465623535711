var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      items: _vm.webhookRegisters,
      headers: _vm.headers,
      loading: _vm.loading,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u(
      [
        {
          key: "top",
          fn: function() {
            return [
              _c(
                "v-toolbar",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    {
                      staticClass:
                        "primary--text font-weight-bold section-title"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _vm._t("actions"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "primary--text",
                                    attrs: {
                                      disabled: _vm.hasMaxWebhookRegisters,
                                      outlined: "",
                                      height: "36px",
                                      width: "86px"
                                    },
                                    on: { click: _vm.createWebhookRegister }
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("actions.add")) + " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.settings.tabs_items.WebhookRegisters.webhook_registers_table.create_webhook_register"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                2
              )
            ]
          },
          proxy: true
        },
        {
          key: "item",
          fn: function(props) {
            return [
              _c(
                "WebhookRegistersTableRow",
                _vm._b(
                  {
                    attrs: { confirm: _vm.$refs.confirm },
                    on: {
                      fetch: function($event) {
                        return _vm.$emit("fetch")
                      },
                      updateWebhookRegister: _vm.updateWebhookRegister,
                      detailsWebhookRegister: _vm.detailsWebhookRegister,
                      deleteWebhookRegister: _vm.deleteWebhookRegister
                    }
                  },
                  "WebhookRegistersTableRow",
                  props,
                  false
                )
              )
            ]
          }
        },
        _vm.$can("create", "AdminUser")
          ? {
              key: "body.append",
              fn: function() {
                return [_c("ConfirmationModal", { ref: "confirm" })]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }