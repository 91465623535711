<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
        persistent
    >
        <template #icon>
            <v-icon color="indigo accent-2">fas fa-user-plus</v-icon>
        </template>

        <WebhookRegisterForm
            ref="form"
            v-model="webhookRegister"
            formType="CREATE"
            @submit="confirm"
            :disabled="loading"
            :isFormValid.sync="isFormValid"
        />
    </BaseModal>
</template>

<script>
import WebhookRegisterForm from '@/components/forms/WebhookRegisterForm'
import WebhookRegister from '@/models/WebhookRegister'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from './BaseModalMixin'

export default {
    name: 'CreateWebhookRegisterModal',
    components: {
        BaseModal, WebhookRegisterForm,
    },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        title: vm.$t('modals.CreateWebhookRegisterModal.title'),

        webhookRegister: new WebhookRegister,
        loading: false,
        isFormValid: false,
    }),
    methods: {
        async beforeOpen() {
            this.webhookRegister = new WebhookRegister
            return true
        },
        triggerSubmit() {
            this.$refs.form.submit()
        },
        async beforeConfirm(v) {
            this.loading = true
            let createdWebhookRegister = await this.webhookRegister.create()
                .catch(this.preErrorHandler)
            this.loading = false

            if (!createdWebhookRegister)
                return

            this.resolveValue = createdWebhookRegister

            return true
        },
        preErrorHandler(error) {
            let knownCodes = [
                'used_url',
            ]

            let code = this.$lodash.get(error, 'response.data.code')
            if (!knownCodes.includes(code))
                this.errorHandler(error)

            this.$bus.$emit('alert', this.$t(`modals.CreateWebhookRegisterModal.errors.${code}`), 'error')
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>