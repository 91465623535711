import { render, staticRenderFns } from "./CustomFieldFieldset.vue?vue&type=template&id=f838488c&scoped=true&"
import script from "./CustomFieldFieldset.vue?vue&type=script&lang=js&"
export * from "./CustomFieldFieldset.vue?vue&type=script&lang=js&"
import style0 from "./CustomFieldFieldset.vue?vue&type=style&index=0&id=f838488c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f838488c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCheckbox,VCol,VList,VListItem,VListItemAction,VRow,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f838488c')) {
      api.createRecord('f838488c', component.options)
    } else {
      api.reload('f838488c', component.options)
    }
    module.hot.accept("./CustomFieldFieldset.vue?vue&type=template&id=f838488c&scoped=true&", function () {
      api.rerender('f838488c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/forms/fieldset/CustomFieldFieldset.vue"
export default component.exports