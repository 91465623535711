<template>
    <v-col cols="12" class="pr-md-5 py-0">
        <v-row>
            <v-col cols="12" class="py-0">
                <div class="primary--text"><b>{{ $t('forms.WebhookRegisterForm.sections.types.title') }}</b></div>
            </v-col>
        </v-row>

        <div v-for="(type, index) in value.types" :key="index" class="pt-3">
            <v-row>
                <v-col cols="12" class="py-0">
                    <cp-select-field
                        ref="select_type_input"
                        :placeholder="$t('forms.WebhookRegisterForm.sections.types.placeholder')"
                        :label="$t('forms.WebhookRegisterForm.sections.types.label')"
                        :hint="$t('forms.WebhookRegisterForm.sections.types.hint')"
                        :error-messages="getErrorMessages('types[0]')"
                        :items="getAvailableTypesFor(index)"
                        item-value="code"
                        item-text="label"
                        v-model="value.types[index]"
                        :clearable="true"
                        @input="clearErrorMessages('types[0]')"
                        :disabled="isDetails"
                        :append-icon="!isDetails ? '$dropdown' : ''"
                        required
                    >
                    </cp-select-field>
                </v-col>
            </v-row>

            <v-btn v-if="!isDetails && !isUniqueType(index)" @click="removeType(index)" text class="background error--text mb-3">{{ $t('forms.WebhookRegisterForm.sections.types.remove_type') }}</v-btn>

            <v-divider v-if="!isDetails || !isLastType(index)"></v-divider>
        </div>

        <v-row v-if="allowAddTypes">
            <v-col cols="12" class="">
                <v-btn v-if="!isDetails" @click="addType" text class="background primary--text">{{ $t('forms.WebhookRegisterForm.sections.types.add_type') }}</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import WebhookRegister from '@/models/WebhookRegister'

export default {
    name: 'TypesFieldset',
    mixins: [ FormMixin(WebhookRegister) ],
    props: {
        value: WebhookRegister,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    created() {
        this.value.types = this.value.types || ['']
    },
    methods: {
        addType() {
            this.value.types.push('')
        },
        removeType(index) {
            this.value.types.splice(index, 1)
        },
        getAvailableTypesFor(index) {
            return this.availableTypes.concat(this.value.types[index] || [])
        },
        isUniqueType(index) {
            return this.value.types && this.value.types.length == 1 && index == 0
        },
        isLastType(index) {
            return this.value.types && index == this.value.types.length - 1
        },
    },
    computed: {
        availableTypes() {
            let types = WebhookRegister.getAvailableTypes(this.value.types)
            return types.map(type => {
                return { code: type, label: WebhookRegister.getLabelForType(type) }
            })
        },
        isDetails() {
            return this.formType == 'DETAILS'
        },
        allowAddTypes() {
            return this.$lodash.get(this, 'value.types', []).length < WebhookRegister.getAvailableTypes().length
        },
    },
}
</script>